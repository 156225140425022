import { AggregateService } from "lib/api/api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { V1CombinedUserItem } from "../common/data-contracts";
import { HttpErrorType } from "../types";

export const useGetUsersQueryKey = "get-users";

type Options = UseQueryOptions<
    V1CombinedUserItem[],
    HttpErrorType,
    V1CombinedUserItem[],
    [
        typeof useGetUsersQueryKey,
        string | undefined,
        string | undefined,
        string | undefined,
    ]
>;

export function useGetUsersQuery(
    args?: {
        name_pattern?: string;
        b2b_company_id?: string;
        vendor_id?: string;
    },
    options?: Options,
) {
    return useQuery(
        [
            useGetUsersQueryKey,
            args?.b2b_company_id,
            args?.vendor_id,
            args?.name_pattern,
        ],
        async () => {
            return (await AggregateService.uiBackendServiceListUsers(args))
                .data;
        },
        options,
    );
}

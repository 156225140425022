import queryString from "query-string";

import { IS_WINDOW_DEFINED } from "lib/constants/is-window-defined";
import { QUERY_PARAMS_FORMAT } from "./constants";

export const parseQueryStringByKey = (key: string) => {
    if (IS_WINDOW_DEFINED) {
        const parsedQuery = queryString.parse(
            window.location.search,
            QUERY_PARAMS_FORMAT,
        );

        return queryString.parse(
            parsedQuery[key] as string,
            QUERY_PARAMS_FORMAT,
        );
    }
};

import React from "react";

import { ILabelProps } from "./label.interface";
import { StyledLabel } from "./label.styled";

export const Label = ({
    children,
    capitalize = true,
    ...props
}: ILabelProps) => {
    return (
        <StyledLabel capitalize={capitalize} {...props}>
            {children}
        </StyledLabel>
    );
};

export interface FilterLayoutProps {
    onCloseFilter: () => void;
    onSubmit: () => void;
    onReset: () => void;
    totalActiveFilters: number;
    isDirty: boolean;
    testId?: string;
}

export const testIds = {
    applyFilterButton: "apply-filters-button",
    resetFilterButton: "reset-filters-button",
    closeButton: "close-button",
};

import { useTranslation } from "next-i18next";
import { PropsWithChildren, useState } from "react";

import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { FilterAccordionProps, testIds } from "./filter-accordion.interface";
import {
    AccordionHeadingButton,
    AccordionHeadline,
    AccordionItem,
    ItemTitle,
    StyledLeftBlock,
    StyledRightBlock,
    StyledFilterClearButton,
    StyledItemContent,
    StyledSelectedItemsCount,
} from "./filter-accordion.styled";

export const FilterAccordion = ({
    reset,
    title,
    totalActiveFilters = 0,
    openedByDefault = false,
    children,
    testId,
}: PropsWithChildren<FilterAccordionProps>) => {
    const { t } = useTranslation("common");
    const [isActive, setIsActive] = useState(openedByDefault);

    return (
        <AccordionItem>
            <AccordionHeadline>
                <StyledLeftBlock data-testid={testId}>
                    <AccordionHeadingButton
                        type="button"
                        isActive={isActive}
                        onClick={() => setIsActive((prev) => !prev)}
                        data-testid={`${testId}-${testIds.accordionButton}`}
                    >
                        <Icon name={Icons.upArrowSvg} />
                    </AccordionHeadingButton>
                    <ItemTitle title={title}>{title}</ItemTitle>
                    {/* <InfoButton /> */}
                </StyledLeftBlock>
                <StyledRightBlock data-testid={`${testId}-clear`}>
                    <StyledFilterClearButton
                        data-testid={`${testId}-${testIds.clearButton}`}
                        hasActiveFields={!!totalActiveFilters}
                        type="button"
                        disabled={!totalActiveFilters}
                        onClick={reset}
                        title={t("filter.button.clear", "Clear")}
                    >
                        {t("filter.button.clear", "Clear")}
                    </StyledFilterClearButton>
                    <StyledSelectedItemsCount
                        hasActiveFields={!!totalActiveFilters}
                    >
                        {totalActiveFilters}
                    </StyledSelectedItemsCount>
                </StyledRightBlock>
            </AccordionHeadline>
            <StyledItemContent isActive={isActive}>
                {isActive && children}
            </StyledItemContent>
        </AccordionItem>
    );
};

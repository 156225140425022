export type FilterAccordionProps = {
    reset: () => void;
    title: string;
    totalActiveFilters: number;
    openedByDefault?: boolean;
    testId?: string;
};

export const testIds = {
    clearButton: "clear-button",
    accordionButton: "accordion-button",
};

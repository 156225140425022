import { useRouter } from "next/router";
import { useEffect } from "react";
import { isEqual } from "lodash";

import { stringifyQueryString } from "lib/utils/query-string/stringify-query-string";
import { useUserData } from "lib/hooks/use-user-data.hook";

export const useSyncWithQueryParams = (
    params: Record<string, Record<string, any>>,
) => {
    const { asPath, query, isReady, replace } = useRouter();
    const { auth } = useUserData();

    const isAllowedPath =
        (!auth?.access && asPath.startsWith("/auth/")) || !!auth?.access;

    const queryParams = Object.keys(params).reduce(
        function (previous, key) {
            return {
                ...previous,
                [key]: stringifyQueryString(params[key]),
            };
        },
        { ...query },
    );

    const isParamsChanged = isEqual(queryParams, query);

    useEffect(() => {
        if (isReady && isAllowedPath) {
            void replace({ query: queryParams });
        }
    }, [isReady, isParamsChanged, isAllowedPath]);
};

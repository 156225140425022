export const sortAllArraysInObject = (data: Record<string, any>) => {
    const newObj: Record<string, any> = {};

    for (const key in data) {
        if (typeof data[key] === "object") {
            if (Array.isArray(data[key])) {
                newObj[key] = data[key]
                    .map((item: Record<string, any>) =>
                        typeof item === "object"
                            ? sortAllArraysInObject(item)
                            : item,
                    )
                    .sort();
            } else {
                newObj[key] = sortAllArraysInObject(data[key]);
            }
        } else {
            newObj[key] = data[key];
        }
    }

    return newObj;
};

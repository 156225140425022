import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { Label } from "components/atoms/label/label.component";
import { ACTIVE, INACTIVE, WAITING } from "lib/constants/status";
import {
    LabelData,
    testIds,
    UserStatusLabelProps,
} from "./user-status-label.interface";

export const UserStatusLabel = ({
    isBanned,
    isConfirmed,
    testId = testIds.userStatus,
}: UserStatusLabelProps) => {
    const { t } = useTranslation("users");

    const { status, message } = useMemo<LabelData>(() => {
        if (!isConfirmed) {
            return { status: WAITING, message: t("label.waiting", "Waiting") };
        }
        return isBanned
            ? { status: INACTIVE, message: t("label.inactive", "Inactive") }
            : { status: ACTIVE, message: t("label.active", "Active") };
    }, [isBanned, isConfirmed, t]);

    return (
        <Label data-testid={testId} status={status}>
            {message}
        </Label>
    );
};

export const NOT_ASSIGNED_SUB_ROLE_VALUE = "not_assigned_sub_role";

export const NOT_ASSIGNED_SUB_ROLE_LABEL = "Not assigned";

export const NOT_ASSIGNED_SUB_ROLE_OPTION = {
    value: NOT_ASSIGNED_SUB_ROLE_VALUE,
    label: NOT_ASSIGNED_SUB_ROLE_LABEL,
    "data-testid": `${NOT_ASSIGNED_SUB_ROLE_VALUE}-option`,
};

export const getSubRoleIdOrUndefined = (subRoleId?: string) =>
    subRoleId === NOT_ASSIGNED_SUB_ROLE_VALUE ? undefined : subRoleId;

import { useLayoutEffect, useMemo, useState } from "react";
import { cloneDeep, isEqual } from "lodash";

import { getActiveFiltersCount } from "lib/hooks/use-filter/utils/get-active-filters-count/get-active-filters-count";
import { sortAllArraysInObject } from "lib/hooks/use-filter/utils/sort-all-arrays-in-object/sort-all-arrays-in-object";

export type BaseFilterState = Record<string, string[]>;

export const useFilter = <T extends BaseFilterState>(
    value: T,
    onChange: (value: T) => void,
) => {
    const clonedValue = cloneDeep(value);
    const [filterState, setFilterState] = useState<T>(clonedValue);

    useLayoutEffect(() => {
        setFilterState(cloneDeep(value));
    }, [value]);

    const isDirty = !isEqual(
        sortAllArraysInObject(clonedValue),
        sortAllArraysInObject(filterState),
    );

    const totalActiveFilters = useMemo(
        () => getActiveFiltersCount(value),
        [value],
    );

    const submit = () => {
        onChange(cloneDeep(filterState));
    };

    const reset = () => {
        onChange({} as T);
    };

    const resetByKey = (key: keyof T) => () => {
        delete clonedValue[key];
        onChange(clonedValue);
    };

    const setFilterStateByKey =
        (key: keyof T) => (newFilterState: T[keyof T]) => {
            setFilterState((state: T) => {
                // to trigger re-render and escape side effects
                const clonedState = cloneDeep(state);
                if (newFilterState.length) {
                    clonedState[key] = [...newFilterState] as T[keyof T];
                } else {
                    delete clonedState[key];
                }

                return clonedState;
            });
        };

    return {
        filterState,
        setFilterStateByKey,
        totalActiveFilters,
        isDirty,
        submit,
        reset,
        resetByKey,
    };
};

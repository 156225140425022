import styled from "styled-components";

import { colors } from "lib/palette";

export const StyledItemContent = styled.div<{ isActive?: boolean }>`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    ${({ isActive }) =>
        isActive
            ? `border-bottom: 1px solid ${colors.blue_3};`
            : "display:none"};
`;

export const AccordionItem = styled.div``;

export const AccordionHeadline = styled.div`
    width: 100%;
    padding: 15px 20px;
    border-bottom: 1px solid ${colors.blue_3};
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
`;
export const AccordionHeadingButton = styled.button<{ isActive?: boolean }>`
    outline: none;
    background: transparent;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    display: block;

    svg {
        transition: 0.5s;
        transform: rotate(0deg);
    }
    ${({ isActive }) =>
        !isActive &&
        ` svg {
          transform:rotate(180deg);
          transition: 0.5s;
    }`}
`;

const StyledAccordionHeadChildBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
`;

export const StyledLeftBlock = styled(StyledAccordionHeadChildBlock)`
    flex: 1;
`;
export const StyledRightBlock = styled(StyledAccordionHeadChildBlock)``;

export const ItemTitle = styled.h2`
    max-width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.black_1};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledSelectedItemsCount = styled.span<{
    hasActiveFields: boolean;
}>`
    padding: 1px 10px;
    border-radius: 99px;
    background: ${({ hasActiveFields }) =>
        hasActiveFields ? colors.blue_3 : colors.black_5};
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: ${({ hasActiveFields }) =>
        hasActiveFields ? colors.black_6 : colors.black_3};
`;

export const StyledFilterClearButton = styled.button<{
    hasActiveFields: boolean;
}>`
    background: none;
    border: none;
    outline: none;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${({ hasActiveFields }) =>
        hasActiveFields ? colors.black_6 : colors.black_3};
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;

    svg {
        margin-right: 7px;
    }
`;

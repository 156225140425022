import { UiBackendv1GetUserResponse } from "lib/api/common/data-contracts";

export const getUsername = (user: {
    first_name?: string;
    last_name?: string;
    email: string;
}) =>
    user?.first_name || user?.last_name
        ? `${user?.first_name} ${user.last_name}`
        : user?.email;

export const getNameByUser = (data?: UiBackendv1GetUserResponse) => {
    return (
        data?.user &&
        getUsername({
            ...data.user.profile,
            email: data.user.general.email,
        })
    );
};

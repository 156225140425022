import { ACTIVE, INACTIVE, WAITING } from "lib/constants/status";

export type UserStatusLabelProps = {
    isBanned?: boolean;
    isConfirmed?: boolean;
    testId?: string;
};

export type LabelData = {
    status?: typeof ACTIVE | typeof INACTIVE | typeof WAITING;
    message: string;
};

export const testIds = { userStatus: "user-status" };

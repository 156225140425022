import { PropsWithChildren } from "react";
import { useTranslation } from "next-i18next";

import { Button } from "components/atoms/button/button.component";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { testIds, FilterLayoutProps } from "./filter-layout.interface";
import {
    StyledBody,
    StyledBodyContent,
    StyledFilterBlock,
    StyledFilterIconBlock,
    StyledFilterTotalCount,
    StyledFooter,
    StyledFooterButtonBlock,
    StyledHeader,
    StyledHeaderText,
    StyledInfoBlock,
    StyledModalCloseButton,
} from "./filter-layout.styled";

export const FilterLayout = ({
    onCloseFilter,
    onSubmit,
    onReset,
    totalActiveFilters,
    isDirty,
    children,
    testId,
}: PropsWithChildren<FilterLayoutProps>) => {
    const { t } = useTranslation("common");

    return (
        <StyledFilterBlock data-testid={testId}>
            <StyledHeader>
                <StyledInfoBlock>
                    <StyledFilterIconBlock>
                        <Icon name={Icons.headerFilterSvg} />
                    </StyledFilterIconBlock>
                    <StyledHeaderText
                        title={t("filter.title.filter", "Filter")}
                    >
                        {t("filter.title.filter", "Filter")}
                    </StyledHeaderText>
                    <StyledFilterTotalCount
                        hasActiveFilters={!!totalActiveFilters}
                    >
                        {totalActiveFilters}
                    </StyledFilterTotalCount>
                </StyledInfoBlock>

                <StyledModalCloseButton
                    onClick={onCloseFilter}
                    data-testid={testIds.closeButton}
                >
                    <Icon name={Icons.closeSvg} />
                </StyledModalCloseButton>
            </StyledHeader>
            <StyledBody>
                <StyledBodyContent>{children}</StyledBodyContent>
            </StyledBody>
            <StyledFooter>
                <StyledFooterButtonBlock>
                    <Button
                        onClick={onSubmit}
                        disabled={!isDirty}
                        data-testid={testIds.applyFilterButton}
                        width={111}
                        showTitle
                    >
                        {t("filter.button.applyFilter", "Apply filter")}
                    </Button>
                </StyledFooterButtonBlock>
                <StyledFooterButtonBlock>
                    <Button
                        onClick={() => onReset()}
                        disabled={!totalActiveFilters}
                        data-testid={testIds.resetFilterButton}
                        width={77}
                        showTitle
                    >
                        {t("filter.button.reset", "Reset")}
                    </Button>
                </StyledFooterButtonBlock>
            </StyledFooter>
        </StyledFilterBlock>
    );
};

import { PermissionsService } from "lib/api/api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { V1SubRole } from "../common/data-contracts";
import { HttpErrorType } from "../types";

export const useGetRolesQueryKey = "get-roles";

type Options = Omit<
    UseQueryOptions<
        V1SubRole[],
        HttpErrorType,
        V1SubRole[],
        (typeof useGetRolesQueryKey)[]
    >,
    "queryKey" | "queryFn"
>;

export function useGetRolesQuery(options?: Options) {
    return useQuery(
        [useGetRolesQueryKey],
        async () => {
            return (await PermissionsService.permissionServiceListSubRoles())
                .data;
        },
        options,
    );
}

export interface AvatarProps {
    avatarImageUrl: string;
    size?: number;
    testId?: string;
}

export interface StyledAvatarProps {
    size: number;
}

export enum DataTestIds {
    AVATAR_IMAGE = "avatar-image",
}

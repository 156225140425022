import styled, { css } from "styled-components";

import { ILabelProps } from "./label.interface";
import { colors } from "lib/palette";

const statusColors = {
    active: colors.green_2,
    inactive: colors.red_3,
    waiting: colors.yellow_2,
};

export const StyledLabel = styled.div<ILabelProps>`
    width: max-content;
    display: inline-block;
    padding: 2px 15px;
    height: auto;
    border-radius: ${({ borderRadius }) =>
        borderRadius ? `${borderRadius}` : "99px"};
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    border: 1px solid transparent;
    color: ${colors.black_6};
    text-transform: ${({ capitalize }) => (capitalize ? "capitalize" : "none")};

    ${({ backgroundColor = colors.green_2 }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
            border: 1px solid transparent;
        `};

    ${({ borderColor }) =>
        borderColor &&
        css`
            background-color: ${colors.white};
            border-color: ${borderColor};
        `};

    ${({ status }) =>
        status &&
        css`
            background-color: ${statusColors[status]};
            border: 1px solid transparent;
        `}
`;

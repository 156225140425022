import styled from "styled-components";

import { colors, shadow } from "lib/palette";
import { breakpoints } from "lib/breakpoints";
import { zIndexes } from "lib/z-indexes";

export const StyledFilterBlock = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledHeader = styled.div`
    padding: 11px 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid ${colors.blue_3};
    position: relative;
    height: 46px;

    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        background: ${shadow.top};
    }
`;

export const StyledInfoBlock = styled.div`
    display: flex;
    width: 100%;
    height: max-content;
    gap: 10px;
    align-items: center;
`;

export const StyledFilterIconBlock = styled.div`
    min-width: 24px;
    height: max-content;
    display: flex;
`;

export const StyledHeaderText = styled.p`
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const StyledFilterTotalCount = styled.div<{
    hasActiveFilters: boolean;
}>`
    width: 25px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ hasActiveFilters }) =>
        hasActiveFilters ? colors.yellow_1 : colors.black_4};
    border-radius: 99px;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    color: ${colors.black_1};
`;

export const StyledModalCloseButton = styled.button`
    width: max-content;
    height: max-content;
    padding: 0;
    background: transparent;
    border: none;
    position: relative;
    transition: 0.3s;
    transform: rotate(0deg);
    color: ${colors.blue_2};
    z-index: ${zIndexes.modalCloseButton};
    display: none;

    @media (max-width: ${`${breakpoints.xl}px`}) {
        display: flex;
    }

    &:hover {
        transform: rotate(-90deg);
        transition: 0.3s;
        color: ${colors.black_1};
    }
`;

export const StyledBody = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
    position: relative;
`;

export const StyledBodyContent = styled.div`
    width: 100%;
`;

export const StyledFooter = styled.div`
    width: 100%;
    position: relative;
    padding: 15px 20px;
    display: flex;
    gap: 10px;
    z-index: ${zIndexes.footer};
    background: ${colors.white};

    &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        background: ${shadow.bottom};
    }
`;

export const StyledFooterButtonBlock = styled.div`
    height: max-content;
    max-width: calc(50% - 5px);
`;
